import React, { Component } from 'react';
import {  Route, Switch, BrowserRouter,Redirect  } from 'react-router-dom';
import { BrowserRouter as HashRouter } from 'react-router-dom'
import { connect } from 'react-redux';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { userActions,menuActions } from './_actions';
// import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Request from "./_services/request.service";
import i18n from "./i18n";
import routes from "./routes";
import Script from 'react-load-script'
import {Helmet} from "react-helmet";
import history from './history';
import AppConfig  from './_services/app.config';
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Pages
const Home = React.lazy(() => import('./views/Pages/Home/Home'));

class AppRouter extends Component {
  componentWillReceiveProps(nextProps) {
    if(nextProps.menu.items && nextProps.menu.error){
      nextProps.history.push("/")
    }else if (nextProps.menu != this.props.menu){
      // alert("xx")

      let firstData = null
      if( nextProps.menu.length > 0){
          firstData = nextProps.menu[0].codeName
          if(nextProps.menu[0].pageList && nextProps.menu[0].pageList.length > 0){
                firstData = nextProps.menu[0].pageList[0].codeName
          }
        }
      let _routes = routes.find(r=>r.code === firstData)


       console.log("_routes");
      if(_routes && _routes.path && nextProps.history.location.pathname === '/custgroup-list'){
        nextProps.history.push( _routes.path)
      }
    }
  }

  render() {
    console.log("AppRouter",this.props.user,this.props.users)
    return <>
    {!(this.props.menu.items && this.props.menu.error) > 0?
      <DefaultLayout  {...this.props}/>
    :null}
    </>
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { users, authentication,menu } = state;
  const { user } = authentication;
  return {
      user,
      users,
      loggingIn,
      menu
  };
}

const AppRouterPage =  connect(mapStateToProps)(AppRouter);

const makeid = (length) => {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}



class App extends Component {
  rand = makeid(10);
  constructor(props) {
    super(props);




    const parsed = queryString.parse(window.location.search);
    let register =  parsed.register

    if((window.location.host && window.location.host.indexOf("alphabox.")> -1 )|| parsed.apb){
        register = true
    }

    if(window.location.pathname === '/register' || window.location.pathname === '/register/box' ){
      register = true
    }


    let config =  AppConfig.getConfig()
    // console.log("config",config)
    this.state = {
      loaded: false,
      loading: false,
      register:register,
      config:config
    }


    if(props.menu.length === 0){
      setTimeout(() => {
        this.roleUserMenu(props)
      }, 50);
    }
    localStorage.setItem("apb",register)
  }


  componentDidMount() {
    this.initData()


  }

  initData(){
    const parsed = queryString.parse(window.location.search);
    // console.log("this.props.match",parsed,window.location.search)

    if(parsed.nasId){
      this.checknasId({
        nasId:parsed.nasId
      })
    }else{
      this.setState({loaded:true})
    }
  }


  checknasId(data) {
    this.setState({
      loaded:true,
      nasId:data && data.nasId? data.nasId :null
    })
  }


roleUserMenu(nextProps){
  if( nextProps.user && nextProps.user.roleId){
    this.getMenu(nextProps.user)
  }
}



  getMenu(user){
    this.setState({loading:true},()=>{
      setTimeout(()=>{
        this.setState({loading:false})
        const { dispatch } = this.props;
        dispatch(menuActions.getMenu(user));
      },50)
    });

  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.user && nextProps.user.custgroupId != (this.props.user?this.props.user.custgroupId:null)){
      this.roleUserMenu(nextProps)
    }
  }


  render() {
    return (
      <>
      <Script url={"//servedby.revive-adserver.net/asyncjs.php?rand="+this.rand} />
      <Script url={"//media.aso1.net/js/code.min.js?rand="+this.rand} />
      <Script url={"//www.google-analytics.com/analytics.js?rand="+this.rand} />
      <Script url={"https://connect.facebook.net/en_US/sdk.js?rand="+this.rand} />


      <HashRouter history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/" name="Home Page" render={props => <Home {...props} config={this.state.config}/>}/>
            </Switch>
          </React.Suspense>
      </HashRouter>
      </>
    );
  }
}


export default connect(mapStateToProps)((App));
