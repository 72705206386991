// import { Dictionary } from './Dictionary'

const EN = {
  "refresh":"Refresh",
  'dashboard':"Dashboard",
  'config_register_form': "Config Register Form",
  'redirect_config': "Redirect Config",
  'options': "Options",
  'hotspot_gateway': "Hotspot Gateway Status",
  'msg_connect': 'You are now connected to the network. You can purchase cards at the office.',
  'nav_information': 'Information',
  'nav_assistance': 'Assisting Programs',
  'nav_discussion': 'Inquiry / Feedback',
  'msg_support_time': 'Open 24 hours',
  'msg_keep_logged_in': 'Keep me logged in',
  'msg_accept': 'Accept',
  'msg_term_of_use': 'the terms of use.*',
  'msg_number_line': 'Number of line',
  'msg_status_normal': 'active',
  'msg_status_error': 'inactive',
  'confirm_delete': 'Do you want to delete?',
  'confirm_copy': 'Do you want to copy?',
  'confirm_delete_yes': 'Yes',
  'confirm_delete_no': 'No',
  'click_other_logout': 'Click to logout the other device from the system',
  'buy_card': 'Buy Card',
  'read': 'read',
  'none_building':'Building not found',
  'errAgreement': 'You must accept the usage agreement by clicking on the checkbox below the password form to login.',
  'errBlankUserPass': 'Please type a Username and Password',
  'errUserPassIncorrect': 'Your Username or Password is incorrect.',
  'errInvalidCustGroup': 'Your card was purchased from a different location. Cards must be used at the location where they were purchased.',
  'errFreeTestDisabled': 'You can not use the free test username here.',
  'errActiveExpireDate': 'Your Card/Account has reached its maximum valid usage date.',
  'errCardExpireDate': 'Your Card/Account has reached its expire date.',
  'errSmartZoneFreetest': 'The usage time of free test username cannot be viewed.',
  'errLockMacInvalid': 'Your Username cannot log in by this computer.',
  'errMacAlreadyUsedPromotion': 'Your internet device has already used the free internet trial. ** 1 free trial / 1 phone number / 1 device **',
  'errPromotionUsernameForOtherMac': 'This username cannot be used on this device. ** 1 free trial / 1 phone number / 1 device **',
  'errLoginData': 'Information for login not complete. Please click the link below and try again.',
  'errLoginToRMS': 'Fail login to SmartRMS.',
  'errRMSCardNotFound': 'You have not purchased a card yet. Please buy the card first.',
  'errLoginFail': 'Login fail try again.',
  'errOther':'Connection error',
  'errOldNewPasswordSame': "Old password and new password are the same.",
  'errOldNewPasswordMin': "Password must be 4-20 characters.",
  'errMobiledMin': "Mobile must be 10 characters.",
  'errMobiledMax': "Mobile must be 10 characters.",
  "errOldNewPasswordRegex":"You can enter a-z A-Z 0-9 and special characters, except ' \" and ;",
  "passwordInfo1":"Notice:",
  "passwordInfo2":"- Password must be 4-16 characters.",
  "passwordInfo3":"- Alphabets a-z, A-Z and numbers 0-9 and special characters are allowed.",
  "passwordInfo4":"- Special characters that can be entered:",
  "passwordInfo5":"   ! # $ % & ( ) * + , - . / : < = > ? @ [ \ ] ^ _ ` { | } ~",
  "username":"Username",
  "singIn":"Sign in",
  "email":"Email",
  "password":"Password",
  "firstName":"First Name",
  "lastName":"Last Name",
  "birthday":"Birthday",
  "idCard":"ID Card",
  "passport":"Passport",
  "citizenId":"ID Card",
  "passportNo":"Passport",
  "register":"Register",
  "female":"Female",
  "male":"Male",
  "gender":"Gender",
  "mobileNo":"Mobile No",
  "occupation":"Occupation",
  "nationality":"Nationality",
  "vNasId":"Please input your nasId",
  "vNasIdPattern":"NasId information was incorrect",
  "vUsername":"Please input your Username!",
  "vPassword":"Please input your Password!",
  "vNewPassword":"Please input your New Password!",
  "vConfirmPassword":"Please input your Confirm Password!",
  "vEmail":"Please input your Email!",
  "vIDcard_":"ID card information was incorrect",
  "vPassport_":"Passport information was incorrect",
  "errPhoneRegex":"Phone information was incorrect",
  "vConfirmPasswordNotMatch":"New Password and Confirm Password do not match.",
  "vConfirmPasswordNotMatch2":"Password and Confirm Password do not match.",
  "vMobileNo":"Please input your Mobile No!",
  "vFirstName":"Please input your First Name!",
  "vName":"Please input your Name!",
  "vLastName":"Please input your Last Name!",
  "vBirthday":"Please input your Birthday!",
  "vIdCard":"Please input your ID Card or your Passport!",
  "vPassport":"Please input your ID Card or your Passport!",
  "vGender":"Please input your Gender!",
  "rememberMe":"Keep me logged in",
  "forgotPassword":"Forgot your password?",
  "newPassword":"New Password",
  "vOldPassword":"Please input your Old Password!",
  "oldPassword":"Old Password",
  "confirmPassword":"Confirm Password",
  "signIn":"Sign in",
  "back":"Back",
  "registerNow":"Register",
  "loginForFree":"Login for free",
  "registerText":"Register",
  "or":"Or",
  "welcome":"Welcome",
  "dateOfBirth":"Date of Birth",
  "loginWithFacebook":"Login with Facebook",
  "loginWithERegister":"Login with Email (E-Register)",
  "vNotRobot":"Please click the box above to confirm that you are not a robot.",
  "editProfile":"Profile",
  "changePassword":"Change Password",
  "savePassword":"Save Password",
  "submit":"Confirm",
  "save":"Update",
  "cancel":"Cancel",
  "usageHistory":"Usage History",
  "memberForgot":"Change password",
  "reverse":"Reset",
  "resetPassword":"Reset Password",
  "reset":"Reset",
  "resetPasswordSub":"Enter your email below to receive your password reset instructions.",
  "resetPasswordSub2":"Enter your username below to receive your password reset instructions.",
  "login":"Login",
  "name":"Name",
  "startDate":"Start Date",
  "validUntil":"Valid Until",
  "remainingTime":"Remaining Time",
  "on":"on",
  "signOut":"Sign out",
  "cardType":"Card Type",
  "timeLimit": "Time Limit",
  "timeUsed": "Time Used",
  "timeRemaining": "Remaining Time",
  "startTime":"Start Time",
  "stopTime":"Stop Time",
  "upload":"Upload (Mb)",
  "download":"Download (Mb)",
  "sessionTime":"Session Time",
  "usageDetails":"Usage History",
  "total":"Total",
  "contactUs":"Contact Us",
  "lblStampLink":"Click here to logout the other device from the system",
  "already":"You are already logged in on another device",
  "warningChagepassword":"**This will change your password when login <br/> by email (e-register) only.",
  "successfullyRegisteredAndLogged":"Successfully registered and logged in. ",
  "emailNotConfirmed":"This email has not been confirmed. ",
  "facebookLoginWith":"Facebook login with",
  "chilliError":"Unable to connect to server. Please try again.",
  "tryAgain":"Try again",
  "otp":"Register - Confirm OTP",
  "otp_2":"Forgot password - Confirm OTP",
  "continue":"Continue",
  "resendOtp":"new OTP",
  "mOtp1":"Please enter OTP 6 digits",
  "mOtp2":" ",
  "mOtp3":"Reference no",
  "mOtp4":"OTP will expire at",
  "smotp":"Mobile No. for receive OTP",
  "edit": "Edit",
  "remarks": "Remarks",
  "remarks_otp": "Unable to close the phone number entry field. Because you have chosen the OTP registration confirmation method.",
  "status-3": "Instead of downtime that is more than 1 day",
  "status-2": "Instead of downtime greater than 10 minutes",
  "status-1": "Instead of downtime greater than or equal to 7 minutes",
  "status-0": "Instead of downtime that is less than 7 minutes",
  "auto_refresh": "Auto Refresh",
  "customer_code": "Customer Code",
  "building_name_th":  "Location Name (TH)",
  "building_name_en":  "Location Name (EN)",
  "building_name_th2": "Establishment (TH)",
  "building_name_en2": "Establishment (EN)",
  "vBuildingNameTH":"Please input your Location Name (TH).",
  "vBuildingNameEN":"Please input your Location Name (EN).",
  "vBuildingNameTH2":"Please input your Establishment (TH).",
  "vBuildingNameEN2":"Please input your Establishment (EN).",
  "service_phone": "Service Phone",
  "announce": "Announce",
  "announce_expire": "Announce Expire",
  "social_login": "Enable Social Login",
  "package_social": "Package Social",
  "confirm_register_type": "Confirm to Register Type",
  "enable_login": "Enable Login with %{social}",
  "enable_feature_redirect": "Enable Feature Redirect",
  "add": "Add",
  "close": "Close",
  "ok": "OK",
  "url": "URL",
  "delete": "Delete",
  "view": "View",
  "redirect_title_popup": "%{action} Redirect Config",
  "service_type": "Service Type",
  "logout": "Logout",
  "choose_location":"Choose a location",
  "register_alpha": "Register Alpha Box",
  "customer_current": "Current customer",
  "customer_new": "New customer",
  "radio_auto_login": "Auto login until I logout explicitly",
  "radio_save_user": "Save my user name",
  "radio_always_ask": "Always ask for my user name and password",
  "owner_name": "Owner name",
  "owner_phone": "Phone",
  "owner_user": "Username",
  "owner_pass": "Password",
  "acc_name": "Owner name",
  "address": "Address",
  "province": "Province",
  "district": "District",
  "sub_district": "Sub District",
  "zip_code": "Zip code",
  // "vAddress":"กรุณากรอกที่อยู่",
  "vProvince":"Please select your Province.",
  "vDistrict":"Please select your District.",
  "vSubDistrict":"Please select your Sub District.",
  "vPostCode":"Please input your Zip code!",
  "buildingPhone": "Location phone",
  // "vServicePhone":"Please input your Location phone!",
  "servicePhone": "Location phone",
  "nas_id": "nasId",
  "acc_info": "User account information",
  "msg_phone": "* Phone numbers is for account recovery if password forgotten",
  "popup_user2": "The user account name has a maximum length of 64 characters. Only letters a-z, A-Z and numbers 0-9 are allowed.",
  "popup_user": "The user account name has a maximum length of 64 characters. <br>- Only letters a-z, A-Z and numbers 0-9 are allowed.",
  "popup_pass": "Password must be 4-16 characters long. <br>- The letters A-Z, A-Z and 0-9 numbers and special characters are allowed. <br>- Special characters that can be entered include: <br>! # $ % & ( ) * + , - . / : < = > ? @ [ ] ^ _ ` { | } ~",
  "next": "Next",
  "cr_back": "Back",
  "location": "Location",
  "popup_nas_id": "ตัวอย่าง nasId เช่น XX-XX-XX-XX-XX-XX",
  "name_location":"Location Name",
  "vNameLocation":"Please select your Location Name",
  "changeTheme":"Change Theme",
  "log":"Computer Crime Act Logs",
  "social-report":"Social Report",
  "login-report":"Login Report",
  "user_management":"User Management",
  "createUser":"Create User Account",
  "locationInformation":'Location Information',
  "confirmRegistration":"Confirm Registration",
  "please_select":'Please Select',
  "ChooseExistingLocation":"Choose existing location",
  "create_user_account": "Create user account",
  "location_title": "Location",
  "confirm_connection": "Confirm Registration",
  "background_image_system":"Use the default background image from the system",
  "customize_background_yourself":"Customize the background image by yourself",
  "logo_image_system":"Use the default logo image from the system" ,
  "customize_logo_yourself":"Customize the logo image by yourself",
  "preview":"Preview",
  "background":"Background",
  "logo":"Logo",
  "color":"color",
  "format_preview":"Click to select the format for the preview",
  "Upload10":"Upload logo image requires 345 * 345, To see the preview image Images cannot be smaller than 1MB.",
  "UploadBg":"Upload background image requires 800 * 800,To see the preview image Images cannot be smaller than 1MB.",
  "Choose_logo_image":"Choose logo image",
  "Choose_background_image":"Choose background image",
  "add_address": "Add Address",
  "errm1":"Please input your mobile phone no.",
  "errm2":"Please enter your 10-digit mobile phone no.",
  "errm3":"Invalid mobile number format,Please enter your 10-digit mobile phone number to complete.",
  "errm4":"Invalid Location phone format,Please enter again.",
  "vServicePhone":'Please input your Location phone.',
  "vAddress":"Please input your Address.",
  "chooseBuilding":"Choose Building",
  "SendOtp":"Send OTP",
  "ReferenceNo":"Reference no",
  "search":"Search",
  "search2":"Time period to search",
  "detail":"Detail",
  "socialReport":"Social Report",
  "export":'Export',
  'download':'Download',
  'historicalData':'Historical data',
  'from':'From',
  'to':'To',
  'username2':'Username',
  'xusername':'*You can reset your password only for username owned locations.',
  "regis_h1":'Please fill out this form according to the Cyber crime Act.',
  "regis_h2":'Register and confirm the email address for the first time only.',
  "regis_h3":'Register first time only.',
  'advertisement-management':"Advertisement Management",
  "advertisement-1":"advertisement-1",
  "advertisement-2":"advertisement-2",
  "advertisement-3":"advertisement-3",
  "advertisement-4":"advertisement-4",
  "adName": "Ad Name",
  "redirectUrl": "Redirect Url",
  "random": "Random %",
  "package": "Internet Packages",
  "export-excel": "Export Excel",
  "facebookLikePage":"Facebook Like Page"
}

export default EN
